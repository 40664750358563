.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blueprintString {
  word-wrap: break-word;
  font-family: Consolas, monospace;
  font-size: 10.8px;
}
.code {
  font-family: Consolas, monospace;
  font-size: 10.8px;
  display: block;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
}
.alert-fixed {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 9999;
  border-radius: 0;
}
.keyboard-key {
  font-family: monospace;
}
button,
input[type=button] {
  background: none;
  font-weight: 600;
  font-size: 1.5rem;
  transition: all 0.2s;
  position: relative;
}
button.github,
input[type=button].github,
button.facebook,
input[type=button].facebook,
button.twitter,
input[type=button].twitter,
button.google,
input[type=button].google {
  color: #fff;
}
button.github,
input[type=button].github {
  background: #82d465;
}
button.github:after,
input[type=button].github:after {
  background: #5cc437;
}
button.facebook,
input[type=button].facebook {
  background: #3864a3;
}
button.facebook:after,
input[type=button].facebook:after {
  background: #2d5082;
}
button.twitter,
input[type=button].twitter {
  background: #5ea9dd;
}
button.twitter:after,
input[type=button].twitter:after {
  background: #2c8dd0;
}
button.google,
input[type=button].google {
  background: #c30f10;
}
button.google:after,
input[type=button].google:after {
  background: #9c0c0d;
}
.user-photo {
  max-width: 36px;
  max-height: 36px;
}
.user-photo-container {
  display: flex;
  min-width: 300px;
  align-items: flex-start;
}
.user-photo-big {
  max-width: 100px;
  max-height: 100px;
  margin: 6px;
}
.dropzone {
  cursor: pointer;
  padding: 6px 12px;
  height: 100px;
  border: 2px dashed #ff7e00;
  border-radius: 10px;
}
.jumbotron ul {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}
h4 .label {
  line-height: 2.2;
}
/*
tbody > tr > td.icon {
	padding: 3px;
	vertical-align: middle;
}
*/
td.number {
  text-align: end;
}
/*
.item-icon > img {
	border: 1px solid black;
}
*/
.btn-toolbar {
  margin: 0.5em;
}
.card table {
  margin-bottom: 0;
}
[class^="col-"] .card,
[class^="col-"] .img-thumbnail {
  margin-bottom: 4px;
}
.btn + .btn {
  margin-left: 4px;
}
.card {
  margin: 2px;
}
.blueprint-thumbnail.col-auto {
  padding: 2px;
}
.search-row,
.search-form,
.tag-form {
  margin-bottom: 2px;
}
.card > p {
  margin-bottom: 2px;
}
.dropdown-menu {
  padding: 0.5em;
}
.dropdown-menu .btn {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 4px;
}
root {
  width: fit-content;
}
.navbar {
  background: linear-gradient(#faa123, #f89406 60%, #e48806);
}
.card-header {
  background: linear-gradient(#8a9196, #7a8288 60%, #70787d);
  color: #fff;
}
#disqus_thread {
  width: 100%;
}
#disqus_thread {
  color-scheme: none;
}
